.benefitsModal__main {
  display: flex;
  justify-content: flex-end;
}

.benefitsModal__main article {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

