.icon-transform {
  transform: rotate(180deg);
}

.toast-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto !important;
  max-width: var(--width-max-desktop) !important;
  position: fixed !important;
  right: 0;
  top: 80px;
  z-index: var(--z-index-99999);
}

@media (width <= 39.9375em) {
  .toast-container {
    justify-content: center;
    top: 70px;
  }
}
