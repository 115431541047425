.popupCorner{
	height: 25px;
	position: absolute;
	width: 25px;
  background-color: var(--color-base-white);
  border-bottom-color: var(--color-base-white);
  right: 100px;
  top: 20px;
  transform: rotate(45deg);
  box-shadow: var(--box-shadow-2xs);
  z-index: var(--z-index-50);
}

.customPosition {
  box-shadow: none;
  top: -18px;
  display: flex;
  right: 0px !important;
  left: 0 !important;
  width: 1280px !important;
  margin: 0 0 0 auto;
  justify-content: flex-end;
}

.customPosition > div {
  box-shadow: var(--box-shadow-2xs);
  max-width: 512px !important;
  z-index: var(--z-index-50);
}

.customPosition p {
  color: var(--color-base-black) !important;
}