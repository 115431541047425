.shelf>div {
    box-shadow: 20px 20px 80px 0px #1B21380D;
}

.shelf_disabled {
    pointer-events: none;
}

.scrollStyle::-webkit-scrollbar {
    background-color: #fff;
    width: 12px;
    height: 16px;
}   

.scrollStyle::-webkit-scrollbar-track {
    background-color: #fff;
}

.scrollStyle::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

.scrollStyle::-webkit-scrollbar-button {
    display:none;
}