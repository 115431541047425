#nizza-player {
  width: 100%;
}

#nizza-player [class*="modalMain"] {
  padding: 1.2rem !important;
}

#nizza-player [class*="container"] {
  margin-top: 3px !important
}

#nizza-player [class*="buttonContenModal"]>button {
  border-radius: 12px !important;
  color: white !important;
  background: var(--color-primary-red-header) !important;
  font-weight: 600 !important;
  text-transform: inherit !important;
}

#nizza-player [class*="answerContainer"] {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

#nizza-player [class*="success"] {
  background: var(--color-primary-red-header) !important;
}

#nizza-player [class*="answerText"] {
  flex: 4 !important;
}

#nizza-player [class*="noVideoContainer"] {
  border-radius: 12px !important;
}

#nizza-player [class*="playerUi"]  {
  border-radius: 12px !important;
}

@media (min-width: 930px) {
  #nizza-player [class*="videoContent"] {
    height: 370px !important;
    width: 60vw !important;
  }

  #nizza-player [class*="chatContainer"] {
    height: 370px !important;
    width: 31vw !important;
    border-radius: 12px !important;
    background: #F5F5F5 !important;
    justify-content: space-between !important;
  }
  
  #nizza-player [class*="livestreamingContent"] {
    justify-content: space-between !important;
    height: auto !important;
  }

  #nizza-player [class*="inputTextChat"] {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border: none !important;
    background-color: rgb(255, 255, 255) !important;
  }

  #nizza-player [class*="btn"] {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(59, 59, 59) !important;
  }

  #nizza-player [class*="gifIconContainer"] {
    display: flex !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: 2px solid #e3e4e6 !important;
    border-right: 2px solid #e3e4e6 !important;
    background-color: rgb(255, 255, 255) !important;
  }

  #nizza-player [class*="chatBubble"] {
    border-radius: 12px !important;
  }

  #nizza-player [class*="loginForm"]>button {
    border-radius: 12px !important;
    color: white !important;
    background: var(--color-primary-red-header) !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
  }

  
  #nizza-player [class*="label"] {
    font-weight: 400 !important;
    text-transform: inherit !important;
  }

  #nizza-player [class*="loginForm"]  {
    border-radius: 12px !important;
  }

  #nizza-player [class*="closeCardBtn"]  {
    top: 4px !important;
    right: 4px !important;
  }

  #nizza-player [class*="modalMain"] {
    width: 300px !important
  }
}

@media (min-width: 1280px) {
  #nizza-player [class*="videoContent"] {
    height: 390px !important;
    width: 768px !important;
  }

  #nizza-player [class*="chatContainer"] {
    height: 390px !important;
    width: 340px !important;
  }
}

@media (max-width: 930px) {
  #nizza-player [class*="gifIconContainer"] {
    display: flex !important;
    border-top: none !important;
    border-bottom: none !important;
    background-color: rgba(20, 20, 21, 0.4) !important;
    color: rgb(255, 255, 255) !important;
  }
}

@media only screen and (max-width: 930px) and (min-width: 640px)  {
  [class*="playerVideoGrid"] {
    position: absolute;
    height: 100%;
    inset: 0;
    display: grid;
    grid-template-columns: 22px 43.5px 144px auto 95px auto 43.5px 10px 43.5px 10px 43.5px 10px 43.5px 22px;
    grid-template-rows: 10% auto 100px auto 43.5px 17px;
    max-width: 100%;
    min-width: 100%;
    z-index: 2;
  }
}